<template>
  <pharmaceutics-module-component></pharmaceutics-module-component>
</template>

<script>
import PharmaceuticsModuleComponent from "@/components/admin/modules/PharmaceuticsModuleComponent";
export default {
  name: "PharmaceuticsModule",
  title: "Gestión de Farmacias | Baja California Health Tourism",
  components: { PharmaceuticsModuleComponent },
};
</script>

<style scoped></style>
